import { __env } from '../../envloader';

export const editableFileTypes = {
  text: new RegExp(/text\//),
  application: [ "application/yaml", "application/json", "application/x-yaml", "application/pdf" ],
  additionalTypes: [ ...__env.IBIS_TEXT_EDIT_ADDITIONAL_CONTENT_TYPES ]
};

export const isFileEditable = (contentType) => {
  const { text, application, additionalTypes } = editableFileTypes;

  if (!contentType) return false;

  if (!application.includes(contentType) &&
    !additionalTypes.includes(contentType) &&
    contentType.search(text) === -1) {
    return false;
  }
  else {
    return true;
  }
};
