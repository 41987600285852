import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';

import { StyledDropdownButton, StyledDropdownIcon } from '../dropdown/dropdown.styled';
import { Button } from './button';
import MovableModal from '../nodeCreator/movableModal';
import { ButtonModalBody, ButtonModalFooter } from './buttonModal.styled';
import { Input } from '../input/input';
import axiosInstance from '../../../main/utils/axios/axiosInstance';
import { __env } from '../../../envloader';
import useIBIS from '../../hooks/useIbis';
import { icons } from '../../../assets/svg/icons';

const Rename = ({ currentName }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathname = decodeURIComponent(location.pathname);
  const IBIS = useIBIS();

  const [ modal, setModal ] = useState(false);
  const [ newName, setNewName ] = useState(currentName || "");

  const toggleModal = () => {
    setModal(!modal);
  };

  const openModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setModal(true);
  };

  const handleChange = (event) => {
    setNewName(event.target.value);
  };

  const onSubmit = async() => {
    if (newName === currentName) {
      toggleModal();
    }
    else {
      await axiosInstance.post(`${__env.IBIS_TENANT_URL}${pathname?.slice(1)}${newName}/?move=${currentName}`);
      IBIS.fetchData();
      toggleModal();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <>
      {modal &&
        <MovableModal 
          title={t('ibis-ui:edit_name')}
          modal={modal}
          closeModal={toggleModal}
        >
          <ButtonModalBody>
            <label className="hidden-label" htmlFor="name">{t('ibis-ui:edit_name')}</label>
            <Input
              name="name"
              value={newName}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder={t('ibis-ui:edit_name')}
            />
          </ButtonModalBody>

          <ButtonModalFooter>
            <Button
              className="w-100"
              type="secondary"
              onClick={toggleModal}
            >
              {t("ibis-ui:cancel")}
            </Button>
            <Button
              className="w-100"
              onClick={onSubmit}
            >
              {t("ibis-ui:confirm")}
            </Button>
          </ButtonModalFooter>
        </MovableModal>
      }
      <StyledDropdownButton className="px-2" onClick={openModal}>
        <StyledDropdownIcon className="pr-1 align-items-center">
          {icons.rename}
        </StyledDropdownIcon>
        {t('ibis-ui:edit_name')}
      </StyledDropdownButton>
    </>
  );
};

Rename.propTypes = {
  currentName: PropTypes.string
};

export default Rename;
