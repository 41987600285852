import styled from 'styled-components';
import { ModalBody, ModalFooter } from 'reactstrap';

import { theme } from '../../styles';

export const ButtonModalBody = styled(ModalBody)`
  padding: 1rem;
  background-color: ${theme.colors.grey[0]};
`;

export const ButtonModalFooter = styled(ModalFooter)`
  flex-wrap: nowrap;
`;